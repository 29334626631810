.HomeItems {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.05;
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;
}

.home-header {
    margin-top: 15rem;
    font-size: 7.5rem;
    color: white;
    text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
}

.home-content {
    font-size: 2rem;
    color: white;
    margin-bottom: 20rem;
}

.first,
.second,
.third,
.fourth {
    display: inline-block;
    transition: transform .2s;
    white-space: pre;
}

.first:hover,
.second:hover,
.third:hover,
.fourth:hover {
    transform: scale(1.3);
    color: lightblue;
}

@media screen and (max-width: 960px) {
    .HomeItems{
        height: 20rem;
    }
    .home-header {
        font-size: 3rem;
        margin-top: 12.5rem;
    }
    .home-content {
        font-size: 1rem;
        margin-bottom: 14rem;
    }
    .bg {
        object-fit: cover;
    }
}