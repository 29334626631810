    .ContactItems {
        background-color: #283242;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .contact-header {
        color: lightblue;
        text-align: center;
        font-size: 4rem;
        margin-top: 6rem;
        margin-bottom: 2rem;
    }
    
    .contact-form {
        width: 50%;
        height: 50%;
        margin-bottom: 4rem;
    }
    
    .contact-name,
    .contact-email,
    .contact-subject {
        width: 100%;
        height: 2rem;
        margin-bottom: .75rem;
        font-size: 1rem;
        text-indent: 10px;
        border: none;
        border-radius: 2px;
        background-color: #2f3b4d;
        color: white;
        border: 1px solid #597196;
    }
    
    .contact-message {
        width: 100%;
        height: 40vh;
        margin-bottom: .75rem;
        font-size: 1.1rem;
        text-indent: 10px;
        padding-top: 15px;
        border: none;
        border-radius: 2px;
        background-color: #2f3b4d;
        color: white;
        border: 1px solid #597196;
    }
    
    input:focus,
    textarea:focus {
        outline-width: 2px;
        outline-style: outset;
        outline-color: lightblue;
        outline-offset: 0px;
    }
    
    .contact-submit {
        background-color: #2f3b4d;
        border: 1px solid lightblue;
        color: lightblue;
        width: 8rem;
        height: 2.5rem;
        font-size: 1rem;
        border-radius: 2px;
        margin-bottom: 4rem;
    }
    
    .contact-submit:focus {
        outline: none;
    }
    
    .contact-submit:hover {
        background-color: lightblue;
        /* border: 1px solid white; */
        color: #38465c;
        transition: all 0.3s;
    }
    
    @media screen and (max-width: 960px) {
        .contact-header {
            font-size: 3rem;
        }
        .contact-form {
            width: 85%;
        }
    }