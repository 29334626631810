.NavbarItems {
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    top: 0;
    height: 70px;
    display: flex;
    position: sticky;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    z-index: 2;
}

.nav-header {
    justify-self: start;
    margin-left: 40px;
    cursor: pointer;
    color: black;
    padding-left: 7.5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 2px solid black;
    border-radius: 2px;
    font-weight: normal;
    font-size: 1.75rem;
    font-family: 'Montserrat';
    /* font-family: 'Permanent Marker'; */
    /* font-family: 'Pacifico'; */
}

.nav-menu {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 20px;
    list-style: none;
    justify-content: end;
    text-align: end;
    margin-right: 40px;
}

.nav-links {
    color: black;
    text-decoration: none;
    padding-bottom: 5px;
}

.nav-links:hover {
    /* transition: all 0.2s ease-out; */
    border-bottom: 2px solid lightblue;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .nav-header {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-25%, 32.5%);
    }
    .nav-menu {
        position: absolute;
        top: -70px;
        left: 0px;
        width: 100%;
        height: 0px;
        opacity: 0;
        transition: 0.25s ease;
        padding-bottom: 0.5rem;
        /* display: none; */
    }
    .nav-menu.active {
        display: flex;
        flex-direction: column;
        top: 70px;
        height: 430px;
        z-index: 2;
        background-color: white;
        opacity: 1;
        transition: 0.25s ease;
    }
    .nav-links {
        text-align: center;
        padding: 1.1rem;
        width: 100%;
        display: table;
    }
    .nav-links:hover {
        background-color: lightblue;
        border-radius: 0;
        border: none;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-90%, 40%);
        font-size: 2rem;
        cursor: pointer;
    }
}