.ExperienceItems {
    height: auto;
    background-color: #283242;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10rem;
}

.exp-header {
    text-align: center;
    font-size: 4rem;
    color: lightblue;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.exp-img{
    position: relative;
    left: 5%;
    width: 32.5%;
    border-radius: 5px;
    box-shadow: rgba(20, 20, 20, 0.4) -5px 5px, rgba(20, 20, 20, 0.3) -10px 10px, rgba(20, 20, 20, 0.2) -15px 15px, rgba(20, 20, 20, 0.1) -20px 20px, rgba(20, 20, 20, 0.05) -25px 25px;
}

.exp-item {
    width: 50%;
    display: inline-block;
    position: relative;
    left: 10%;
    vertical-align: top;
    text-align: left;
}

.exp-item-title {
    color: white;
    font-size: 2rem;
    margin-top: 60px;
    margin-bottom: 0px;
    border-bottom: 1px solid white;
    padding-bottom: 5px;
}

.exp-item-desc{
    color: lightgrey;
    font-size: 1.1rem;
    margin-top: 20px;
}

.exp-item-time{
    color: lightgrey;
    font-size: 1.1rem;
}

@media screen and (max-width: 960px) {
    .exp-header {
        font-size: 3rem;
        margin-bottom: 0rem;
        /* margin-top: 3rem; */
    }
    .exp-img {
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 19rem;
        margin-bottom: 1rem;
        margin-top: 3rem;
        box-shadow: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .exp-item{
        width: 80%;
    }
    .exp-item-title {
        font-size: 1rem;
        margin-top: 15px;
    }
    .exp-item-desc{
        font-size: 0.6rem;
    }
    .exp-item-time {
        font-size: 0.6rem;
    }
}